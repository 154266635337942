<!-- 综合考场编辑 -->
<template>
  <div class="editSynthetical">
    <!-- 上面部分 -->
    <div class="top">
      <span style="font-size: 16px; font-weight: bold">综合考场编辑</span>
      <el-button class="el-btn" size="small" @click="goBack"> 返回 </el-button>
    </div>
    <!-- 表单部分 -->
    <el-form
      ref="form"
      :model="form"
      id="form-1"
      :rules="rules"
      class=""
      inline
      label-width="150px"
    >
      <el-form-item label="编码">
        <el-input v-model="form.coded" placeholder="" disabled></el-input>
      </el-form-item>
      <el-form-item label="考试场地" prop="field">
        <el-select
          v-model="form.field"
          filterable
          placeholder="请输入并选择考试场地"
          :clearable="true"
        >
          <el-option
            v-for="item in form.fieldOpt"
            :key="item.id"
            :label="item.site_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考场类型">
        <div style="width: 300px">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">机构</el-radio>
            <el-radio :label="2">学科</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="选择机构/学科">
        <el-select
          v-model="form.institution"
          filterable
          placeholder="请输入并选择机构"
          :clearable="true"
          v-if="form.type == 1"
        >
          <el-option
            v-for="item in form.institutionChoose"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="form.subject"
          filterable
          placeholder="请选择学科"
          :clearable="true"
          v-if="form.type == 2"
        >
          <el-option
            v-for="item in form.subjectChoose"
            :key="item.id"
            :label="item.course_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- </el-select> -->
      </el-form-item>
      <el-form-item label="考试大类">
        <el-select
          v-model="form.exam"
          filterable
          placeholder="请选择考试大类"
          :clearable="true"
        >
          <el-option
            v-for="item in form.examOpt"
            :key="item.id"
            :label="item.exam_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试名称" prop="testName">
        <el-input
          v-model="form.testName"
          placeholder="请输入考试名称"
          class="text-ipt"
        ></el-input>
      </el-form-item>
      <el-form-item label="考试日期" prop="testDate">
        <el-date-picker
          v-model="form.testDate"
          type="date"
          placeholder="选择考试日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-time-picker
          value-format="HH:mm"
          format="HH:mm"
          v-model="form.startTime"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="请选择开始时间"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="截止时间">
        <el-time-picker
          value-format="HH:mm"
          format="HH:mm"
          v-model="form.endTime"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="请选择开始时间"
        >
        </el-time-picker>
      </el-form-item>
      <!-- <el-form-item label="选择考场">
                <div style="width: 300px;">
                    <el-radio-group v-model="form.room">
                        <el-radio :label="1">内考场</el-radio>
                        <el-radio :label="2">外考场</el-radio>
                        <el-radio :label="3">其他</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item> -->
      <el-form-item label="考试时长" prop="duration">
        <el-input
          v-model="form.duration"
          placeholder="请输入考试时长"
        ></el-input>
      </el-form-item>
      <el-form-item label="监考老师" prop="invigilator">
        <el-input
          v-model="form.invigilator"
          placeholder="请输入监考老师"
        ></el-input>
      </el-form-item>
      <el-form-item label="考试状态">
        <div style="width: 300px">
          <el-radio-group v-model="form.condition">
            <el-radio :label="1">准备</el-radio>
            <el-radio :label="2">完成</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="可安排人数">
        <el-input
          v-model="form.arrange"
          placeholder="请输入可安排人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="实际考生人数">
        <el-input
          v-model="form.actual"
          placeholder="请输入实际考生人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="说明">
        <el-input
          v-model="form.explain"
          placeholder="请输入"
          type="textarea"
          class="textArea-width"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <!-- 底部 提交与取消 -->
    <div slot="footer" class="dialog-footer">
      <el-button
        style="background-color: #18bc9c; color: #ffffff"
        @click="submitForm('form')"
        >提 交</el-button
      >
      <el-button class="btn" @click="resetForm('form')">取 消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        coded: "", //编码
        field: "", //考试场地
        fieldOpt: [], //考试场地选择
        institution: "", //机构
        institutionChoose: [], //机构选择
        subject: "", //学科
        subjectChoose: [], //学科选择
        testName: "", //考试名称
        type: 1, //考场类型
        testDate: "", //考试日期
        startTime: "", //开始时间
        endTime: "", //截止时间
        room: 1, //选择考场
        duration: "", //考试时长
        invigilator: "", //监考老师
        condition: 1, //考试状态
        arrange: "", //计划考试人数
        actual: "", //实际考生人数
        explain: "", //说明
        exam: "", //考试大类
        examOpt: [], //考试大类选项，
      },
      rules: {
        field: [
          { required: true, message: "考试场地不能为空", trigger: "blur" },
        ],
        testName: [
          { required: true, message: "考试名称不能为空", trigger: "blur" },
        ],
        testDate: [
          { required: true, message: "考试日期不能为空", trigger: "change" },
        ],
        duration: [
          { required: true, message: "考试时长不能为空", trigger: "blur" },
        ],
        invigilator: [
          { required: true, message: "监考老师不能为空", trigger: "blur" },
        ],
      },
      bool: false,
    };
  },
  created() {
    // 考试场地
    this.$request({
      url: "/api/schoolarea/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("考试场地",res.data.list)
      this.form.fieldOpt = res.data.list;
    });

    // 选择机构
    this.$request({
      url: "/api/mechanism/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("选择机构",res.data.list)
      this.form.institutionChoose = res.data.list;
    });

    // 选择学科
    this.$request({
      url: "/api/course/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      // console.log("选择机构",res.data.list)
      this.form.subjectChoose = res.data.list;
    });

    // 初次渲染
    this.$request({
      url: "/api/Examcomprehensive/detail",
      method: "POST",
      data: {
        id: this.$route.query.id,
      },
    }).then((res) => {
      console.log(res);
      let data = res.data.data;
      let form = this.form;
      form.coded = data.number; //编码
      form.field = parseInt(data.exam_room); //考试场地
      form.type = data.examroom_type; //考场类型
      //  选择机构/学科
      if (form.type == 1) {
        form.institution = data.org_id;
      } else if (form.type == 2) {
        form.subject = data.org_id;
      }
      form.institution = data.org_id; //机构
      form.testName = data.exam_name; //考试名称
      form.testDate = data.exam_date; //考试日期
      form.startTime = data.start_date; //开始时间
      form.endTime = data.end_date; //截止时间
      form.duration = data.exam_duration; //考试时长
      form.invigilator = data.invigilator; //监考老师
      form.condition = data.exam_status; //考试状态
      form.arrange = data.exam_num; //计划考试人数
      form.actual = data.exam_num2; //实际考生人数
      form.exam = Number(data.apply_category); // 考试大类
      form.explain = data.remark; //说明
    });
    // 考试大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log(res,"考试大类")
      if (res.code == 1) {
        this.form.examOpt = res.data.list;
      }
    });
  },
  methods: {
    goBack() {
      //返回
      this.$router.go(-1);
    },
    submitForm(form) {
      //提交
      this.$refs[form].validate((valid) => {
        if (valid) {
          // alert('submit!');
          // console.log(this.form)
          if (this.bool) {
            return;
          }
          this.bool = true;
          let org_id = null;
          if (this.form.type == 1) {
            org_id = this.form.institution;
          } else {
            org_id = this.form.subject;
          }
          this.$request({
            url: "/api/Examcomprehensive/edit",
            method: "POST",
            data: {
              number: this.form.coded,
              exam_date: this.form.testDate,
              org_id: org_id,
              exam_name: this.form.testName,
              start_date: this.form.startTime,
              end_date: this.form.endTime,
              exam_duration: this.form.duration,
              invigilator: this.form.invigilator,
              exam_num: this.form.arrange,
              exam_num2: this.form.actual,
              remark: this.form.explain,
              exam_room: this.form.field,
              examroom_type: this.form.type,
              exam_status: this.form.condition,
              id: this.$route.query.id,
              apply_category: this.form.exam,
            },
          })
            .then((res) => {
              console.log(res);
              if (res.code == 1) {
                this.$message({
                  message: "编辑成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1500);
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 1500);
              }
            })
            .catch(() => {
              this.bool = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(form) {
      //取消
      this.goBack();
      this.$refs[form].resetFields();
    },
  },
};
</script>

<style scoped="scoped">
.editSynthetical {
  width: 100%;
  height: 100%;
}
/* 上面部分 */
.top {
  padding-top: 25px;
  margin: 0px 24px 30px 28px;
  display: flex;
  justify-content: space-between;
}

.el-btn {
  background: #18bc9c;
  padding-left: 25px;
  background-position: 5px 7px;
  display: inline-block;
  background-image: url(../../assets/back.png);
  background-repeat: no-repeat;
  color: #ffffff;
}
/* 表单部分 */
.el-form {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

::v-deep .el-form-item__label {
  color: #000000;
}

.el-input {
  width: 300px;
  height: 37px !important;
}

.el-select {
  width: 300px;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #ffffff;
}
/* 性别选择按钮 */
#form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #18bc9c;
}

#form-1 ::v-deep.el-radio__input.is-checked + .el-radio__label {
  color: #18bc9c;
}

#form-1 ::v-deep .el-radio__inner:hover {
  border-color: #18bc9c;
}
/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  padding-bottom: 20px;
}
.btn:hover {
  color: #666;
  border-color: #ccc;
  background-color: #ffffff;
}
</style>
